@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.notification-snackbar--success {
  background-color: $hint-of-green;
  color: $cadmium-green;

  :deep(.snackbar__icon-container) {
    background-color: $north-texas-green;
    color: $white;
  }
  :deep(.base-button__inner) {
    color: $cadmium-green;
  }
}
.notification-snackbar--warn {
  background-color: $lemon-chiffon;
  color: $chocolate;

  :deep(.snackbar__icon-container) {
    background-color: $jonquil;
    color: $chocolate;
  }
  :deep(.base-button__inner) {
    color: $chocolate;
  }
}
.notification-snackbar--error {
  background-color: $pippin;
  color: $rose-wood;

  :deep(.snackbar__icon-container) {
    background-color: $boston-university-red;
    color: $white;
  }
  :deep(.base-button__inner) {
    color: $rose-wood;
  }
}
.notification-snackbar--info,
.notification-snackbar--in-progress {
  background-color: $bubbles;
  color: $oxford-blue;
}
