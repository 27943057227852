@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.snackbar {
  display: flex;
  background-color: $white-smoke;
  color: $charcoal;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);

  .snackbar__icon {
    // Override funky styles
    height: auto;
    top: auto;
  }
}
.snackbar__icon-container {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}
.snackbar__details-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  flex-grow: 1;
}
.snackbar__button-container {
  padding: 10px;
}
.snackbar__close {
  position: static;

  &:hover {
    color: inherit;
  }

  & ::v-deep .ic {
    line-height: inherit;
  }
}
.snackbar__title {
  flex-basis: 100%;
  font-size: 16px;
  font-weight: 500;
}
.snackbar__text {
  flex-basis: 100%;
}
